import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import pdf from "../../../assets/images/unnamed.png";
import jpgimg from "../../../assets/images/jpg.png";
import pngimg from "../../../assets/images/png.png";
import instance from "../../common/Baseurl";
import baseURL from "../../common/Baseurl";
import { useHistory, Redirect, Link, useParams } from 'react-router-dom'
import { BiSearchAlt } from 'react-icons/bi'
import swal from "sweetalert";
import { withRouter } from "react-router";

function Singlefileinfolders(props) {
  let history = useHistory();
  let { id } = useParams();
  const [state, setstate] = useState(null);
  const [filterState, setFilterState]= useState(null)
  const [showcurrentFilepath, setshowcurrentFilepath] = useState(null);
  const [fileType, setfileType] = useState(null);
  const [showmobilestyle, setshowmobilestyle] = useState(false);
  const [activeLink, setactiveLink] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null)
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setloading] = useState(false);
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // var baseURL = 'https://demo.accounting.globe-chain.com:7000'
    var baseURL = 'http://localhost:7000'
  }
  else {
    var baseURL = 'https://accounting.globe-chain.com:7000'

  }
  const download = (event, id, name, user_id, user_name) => {
    event.preventDefault();
    event.stopPropagation()
    // swal('Downloading file', { buttons: false })
    // instance.get(`downloadFiles/${id}/${name}/${user_id}/${user_name}`)
    //   .then(res => {
    //     // console.log(res.data)
    //     if (res.data.response == "success") {
    //       setTimeout(function () {
    //         swal.close()
    //         // let url = window.URL.createObjectURL(blob);
    //         let a = document.createElement('a');
    //         a.href = `/uploads/${user_name}-${user_id}/${name}`;
    //         a.download = `${name}`;
    //         a.click();
    //       }, 2000)

    //     }
    //   })
    //   .catch(err => console.log(err))
    window.open(`${baseURL}/download/${user_name}-${user_id}/${id}/${encodeURIComponent(name)}/${encodeURIComponent(baseURL)}`, '_blank')
    // window.open(`http://localhost:7000/download/${user_name}-${user_id}/${id}/${encodeURIComponent(name)}`,'_blank')
  }

  const report = (event,id, name, user_id, user_name)=>{
    event.preventDefault()
    event.stopPropagation()
    // swal('Generating report from file', { buttons: false })
    // instance.get(`downloadFiles/${id}/${name}/${user_id}/${user_name}`)
    //   .then(res => {
    //     // console.log(res.data)
    //     if (res.data.response == "success") {
    //       setTimeout(function () {
    //         instance.get(`report/${user_name}-${user_id}/${id}/${name}`)
    //           .then(result => {
    //             // console.log(result.data)
    //             if (result.data.response == "success") {
    //               setTimeout(function(){
    //                 let a = document.createElement('a');
    //                 a.href = `/uploads/${user_name}-${user_id}/${name}.xls`;
    //                 a.download = `${name}.xls`;
    //                 a.click();
    //                 swal.close()
    //               },2000)
    //             }
    //           })
    //           .catch(error => console.log(error))
    //       }, 2000)

    //     }
    //   })
    //   .catch(err => console.log(err))
    window.open(`${baseURL}/report/${user_name}-${user_id}/${id}/${encodeURIComponent(name)}/${encodeURIComponent(baseURL)}`, '_blank')
    // window.open(`http://localhost:7000/report/${user_name}-${user_id}/${id}/${encodeURIComponent(name)}`, '_blank')
  }
  const preview = (event,id, name, user_id, user_name) => {
    event.preventDefault()
    event.stopPropagation()
    // swal('Processing file',{buttons:false})
    // instance.get(`downloadFiles/${id}/${name}/${user_id}/${user_name}`)
    //   .then((res) => {
    //     if(res.data.response=="success")
    //     {
    //       setTimeout(function(){
    //         window.open(`/uploads/${user_name}-${user_id}/${name}`,'_blank')
    //         swal.close()
    //       },1000)
    //     }
    // })
    // .catch(err => console.log(err))
    window.open(`${baseURL}/preview/${user_name}-${user_id}/${id}/${encodeURIComponent(name)}/${encodeURIComponent(baseURL)}`, '_blank')
    // window.open(`http://localhost:7000/preview/${user_name}-${user_id}/${id}/${encodeURIComponent(name)}`, '_blank')
  }
  

  const deleteFile= (event, name, id,userid) => {
    event.preventDefault()
    event.stopPropagation()
    swal('Are you sure','You want to delete this file?','info',{buttons:{cancel:'No',view:'Yes'}}).then((view) => {
      if(view=='view')
      {
        // setloading(true)
        swal('Deleting file', { buttons: false })
        instance.delete(`deleteFile/${encodeURIComponent(name)}/${id}/${userid}`).then(res => {
          // setloading(false) 
          // console.log(res.data)
          if(res.data.status=="success")
          {
            swal.close()
            window.location.reload(false)
          }
          
        }).catch(err=>console.log(err))
      }
    })
  }

  const handleClick = (e, i) => { setactiveLink(i); e.preventDefault() }
  if (props.sortbyName === true) {
    if (state && state.length > 0) {
      state.sort((a, b) => a.name.localeCompare(b.name, undefined, { caseFirst: "upper" }))
    }
  }
  if (props.sortbyExtn === true) {
    if (state && state.length > 0) {
      state.sort((a, b) => a.extension.localeCompare(b.extension, undefined, { caseFirst: "upper" }))
    }
  }
  if (props.sortbyDate === true) {
    if (state && state.length > 0) {
      state.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        //console.log("time", (new Date(b.createdTime) - new Date(a.createdTime)))
        return new Date(b.createdTime) - new Date(a.createdTime);
      });
    }
  }

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];

    if (props.files[0] && props.files[0].length > 0) {
      result = props.files[0].filter((d) => {
          return d.name.toLowerCase().includes(value)
      });
    }
    setFilterState(result)
  }

  useEffect(() => {
    if (props.files && props.files.length > 0) {

      const data = [];
      props.files[0].map((f) => {
        //console.log(f)
        data.push(f);
        setstate(data);
        setfileType(f.extension);

      });
    }
    return () => {
      setstate(null);
      setshowcurrentFilepath(null);
      setfileType(null);
    };
  }, [props.files]);
  let productmobile = "No Result Found";
  let product = "No Result Found";
  if(filterState){
    if (filterState.length > 0) {
      console.log(filterState)
      productmobile = filterState.map((f, i) => {
        return (
          <li
            onClick={(i) => {
              setshowcurrentFilepath(f.link);
              setfileType(f.extension);
              setshowmobilestyle(!showmobilestyle);
            }}
            style={{ cursor: "pointer" }}
            key={f.id}
            className="w-100 list-group-item list-group-item-light text-none"
          >
            <a
              style={{ textDecoration: "none", color: "#363636" }}
              href={showcurrentFilepath ? showcurrentFilepath : ""}
              without="true"
              rel="noopener noreferrer"
              target="_blank"
            >
              {f.name}
            </a>
          </li>
        );
      });
    }
  }
  if (filterState) {
    if (filterState.length > 0) {
      product = filterState.map((f, i) => {
        // f.url = preview(f.id, f.name, user.id, user.name)
        return (
          <>
            {props.viewbyList ?
              <li style={{ cursor: "pointer" }}
                key={f.id}
                className="w-100 list-group-item list-group-item-light text-none"
              >
                <span onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation()
                  preview(e, f.id, f.name, user.id, user.name)
                  props.fileurl(previewUrl)
                  props.filetype(f.extension)
                  props.filethumbnail(f.thumbnail)
                  setshowmobilestyle(!showmobilestyle);
                  handleClick(e, i)
                }}>{f.name}</span>
                <button 
                    data-toggle="tooltip" data-placement="left" title="Download"                
                  style={{ float: 'right', color: '#0564DC', padding: '0.2rem', backgroundColor: "white", justifyContent: 'center', border: 'none' }} onClick={(e) => { e.preventDefault(); download(e, f.id, f.name, user.id, user.name) }} type="button"><i className="ri-xl ri-download-2-line"></i></button>
                
                <button 
                    data-toggle="tooltip" data-placement="left" title="Delete"                
                  style={{ float: 'right', color: '#0564DC', padding: '0.2rem', backgroundColor: "white", justifyContent: 'center', border: 'none' }} onClick={(e) => { e.preventDefault(); deleteFile(e, f.name, f.id,  user.user_id) }} type="button">{loading ? (
                    <div className="spinner-border text-light border-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) :<i className="ri-xl ri-delete-bin-7-line"></i>}</button>
                
                <button 
                    data-toggle="tooltip" data-placement="left" title="Preview"                
                  style={{ float: 'right', color: '#0564DC', padding: '0.2rem', backgroundColor: "white", justifyContent: 'center', border: 'none' }} onClick={(e) => { e.preventDefault(); preview(e, f.id, f.name, user.id, user.name) }} type="button"><i className=" ri-xl ri-eye-line"></i></button>
                
              </li>
              :
              <>
                <div
                  style={{ cursor: "pointer", width: '15rem' }}
                  key={f.id}
                  className={
                    "card mb-2 text-center" +
                    (i === activeLink ? " active-state" : "")
                  }
                >
                  <img src={f.extension === 'pdf' ? pdf : [f.extension === 'jpg' ? jpgimg : [f.extension === 'png' ? pngimg : jpgimg]]} className="card-img-top" alt="..." onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation()
                    preview(e,f.id, f.name, user.id, user.name)
                    props.fileurl(previewUrl)
                    props.filetype(f.extension)
                    props.filethumbnail(f.thumbnail)
                    setshowmobilestyle(!showmobilestyle);
                    handleClick(e, i)
                  }} />
                  <div className="card-body">
                    <p className="card-text text-truncate">{f.name}</p>
                  </div>
                  <div className="card-body" style={{display:'inline-block'}}>
                  <button 
                    data-toggle="tooltip" data-placement="left" title="Download"                  
                      style={{ float: 'left', color: '#0564DC', padding: '0.2rem', backgroundColor: "white", justifyContent: 'center', border: 'none', width: '1.5rem', height: '2rem' }} onClick={(e) => { e.preventDefault(); download(e, f.id, f.name, user.id, user.name) }} type="button"><i className="ri-download-2-line"></i></button>
                  <button 
                    data-toggle="tooltip" data-placement="left" title="Preview"                  
                      style={{ float: 'none', color: '#0564DC', padding: '0.2rem', backgroundColor: "white", justifyContent: 'center', border: 'none', width: '1.5rem', height: '2rem' }} onClick={(e) => { e.preventDefault(); preview(e, f.id, f.name, user.id, user.name) }} type="button"><i className="ri-xl ri-eye-line"></i></button>
                  <button 
                    data-toggle="tooltip" data-placement="left" title="Delete"                  
                      style={{ float: 'right', color: '#0564DC', padding: '0.2rem', backgroundColor: "white", justifyContent: 'center', border: 'none' }} onClick={(e) => { e.preventDefault(); deleteFile(e, f.name, f.id,  user.user_id) }} type="button">{loading ? (
                        <div className="spinner-border text-light border-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) :<i className="ri-delete-bin-7-line"></i>}</button>
                  </div>
                </div>
              </>}

          </>
        );
      });
    }
  }
  if (state) {
    if (state.length > 0 && filterState===null) {
      productmobile = state.map((f, i) => {
        return (
          <li
            onClick={(i) => {
              setshowcurrentFilepath(f.link);
              setfileType(f.extension);
              setshowmobilestyle(!showmobilestyle);
            }}
            style={{ cursor: "pointer" }}
            key={f.id}
            className="w-100 list-group-item list-group-item-light text-none"
          >
            <a
              style={{ textDecoration: "none", color: "#363636" }}
              href={showcurrentFilepath ? showcurrentFilepath : ""}
              without="true"
              rel="noopener noreferrer"
              target="_blank"
            >
              {f.name}
            </a>
          </li>
        );
      });
    }
  }
  if (state) {
    if (state.length > 0 && filterState === null) {
      product = state.map((f, i) => {
        // f.url = preview(f.id, f.name, user.id, user.name)
        return (
          <>
            {props.viewbyList ?
              <li style={{ cursor: "pointer" }}
                key={f.id}
                className="w-100 list-group-item list-group-item-light text-none"
                
              >
                <span onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation()
                  preview(e,f.id, f.name, user.id, user.name)
                  props.fileurl(previewUrl)
                  props.filetype(f.extension)
                  props.filethumbnail(f.thumbnail)
                  setshowmobilestyle(!showmobilestyle);
                  handleClick(e, i)
                }}>{f.name}</span>
                <button 
                    data-toggle="tooltip" data-placement="left" title="Download"                
                  style={{ float: 'right', color: '#0564DC', padding: '0.2rem', backgroundColor: "white", justifyContent: 'center', border: 'none' }} onClick={(e) => { e.preventDefault(); download(e, f.id, f.name, user.id, user.name) }} type="button"><i className="ri-xl ri-download-2-line"></i></button>
                
                <button 
                    data-toggle="tooltip" data-placement="left" title="Delete"                
                  style={{ float: 'right', color: '#0564DC', padding: '0.2rem', backgroundColor: "white", justifyContent: 'center', border: 'none' }} onClick={(e) => { e.preventDefault(); deleteFile(e, f.name, f.id,  user.user_id) }} type="button">{loading ? (
                    <div className="spinner-border text-light border-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) :<i className="ri-xl ri-delete-bin-7-line"></i>}</button>
                
                <button 
                    data-toggle="tooltip" data-placement="left" title="Preview"                
                  style={{ float: 'right', color: '#0564DC', padding: '0.2rem', backgroundColor: "white", justifyContent: 'center', border: 'none' }} onClick={(e) => { e.preventDefault(); preview(e, f.id, f.name, user.id, user.name) }} type="button"><i className="ri-xl ri-eye-line"></i></button>
              </li>
              :
              <>
                <div
                  style={{ cursor: "pointer", width: '15rem' }}
                  key={f.id}
                  className={
                    "card mb-2 text-center" +
                    (i === activeLink ? " active-state" : "")
                  }
                >
                  <img src={f.extension === 'pdf' ? pdf : [f.extension === 'jpg' ? jpgimg : [f.extension === 'png' ? pngimg : jpgimg]]} className="card-img-top" alt="..." onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation()
                    preview(e,f.id, f.name, user.id, user.name)
                    props.fileurl(previewUrl)
                    props.filetype(f.extension)
                    props.filethumbnail(f.thumbnail)
                    setshowmobilestyle(!showmobilestyle);
                    handleClick(e, i)
                  }} />
                  <div className="card-body">
                    <p className="card-text text-truncate">{f.name}</p>
                  </div>
                  <div className="card-body" style={{display:'inline-block'}}>
                  <button 
                    data-toggle="tooltip" data-placement="left" title="Download"                  
                      style={{ float: 'left', color: '#0564DC', padding: '0.2rem', backgroundColor: "white", justifyContent: 'center', border: 'none', width: '1.5rem', height: '2rem' }} onClick={(e) => { e.preventDefault(); download(e, f.id, f.name, user.id, user.name) }} type="button"><i className="ri-xl ri-download-2-line"></i></button>
                  <button 
                    data-toggle="tooltip" data-placement="left" title="Preview"                  
                      style={{ float: 'none', color: '#0564DC', padding: '0.2rem', backgroundColor: "white", justifyContent: 'center', border: 'none', width: '1.5rem', height: '2rem' }} onClick={(e) => { e.preventDefault(); preview(e, f.id, f.name, user.id, user.name) }} type="button"><i className="ri-xl ri-eye-line"></i></button>
                  <button 
                    data-toggle="tooltip" data-placement="left" title="Delete"                  
                      style={{ float: 'right', color: '#0564DC', padding: '0.2rem', backgroundColor: "white", justifyContent: 'center', border: 'none' }} onClick={(e) => { e.preventDefault(); deleteFile(e, f.name, f.id,  user.user_id) }} type="button">{loading ? (
                        <div className="spinner-border text-light border-primary" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) :<i className="ri-xl ri-delete-bin-7-line"></i>}</button>
                  </div>
                </div>
              </>}

          </>
        );
      });
    }
  }

  return <>
  <div className="w-100 form-group align-items-center" >
        <label style={{color:"#0564DC"}}><BiSearchAlt style={{color: "#0564DC", fontSize:"30px"}}/> Search File:</label>
        <input style={{ display: "inline-block" , width:"80%", margin: '1rem 0.5rem', padding: '0.5rem', border:"2px solid #0564DC", borderRadius:"16px"}} className="text-primary" type="text" onChange={(event) => handleSearch(event)} placeholder="Search File" />
      </div>

    {props.files[0] && props.files[0].length === 0 ? <div className="text-center mt-5 container alert alert-info p-2">Nothing uploaded</div> : <div className="w-100 h-100 d-flex justify-content-center align-items-start">
      
      <div id="filelistscabinate" className="w-100 d-md-flex flex-wrap justify-content-around align-items-start">
        {product}
      </div>
      <div id="filelistsmobile" className="w-100 h-100">
        <ol className="w-100 text-center list-group list-group-flush list-group-numbered text-break">
          {productmobile}
        </ol>
      </div>
    </div>}
  </>;
}
const mapStateToProps = (state) => {
  return {
    files: state.getFiles.files,
  };
};

export default connect(mapStateToProps)(Singlefileinfolders);