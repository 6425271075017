import React, { useState, useEffect } from "react";
import { Switch, Route, withRouter, NavLink } from "react-router-dom";
import Fileupload from "./Fileupload/Fileupload";
import Myfiles from "./Myfiles/Myfiles";

import Filesinfolders from "./Folders/Filesinfolders";
import "./Dashboard.css";
import swal from "sweetalert";
import { connect } from "react-redux";
import instance from "../common/Baseurl";
import {
  removeuserFolders,
  removeuserFiles,
  fetchUserFolder,
  removeuserrootFolders,
  fetchFolders
} from "../../actions/productActions";
import AccountData from "./Accountdata/AccountData"
import Singlefile from "./Myfiles/Singlefile";
import Userprofile from "./Userprofile/Userprofile";
import Userprofilepic from "./Userprofile/Userprofilepic";
import { AiOutlineLogout, AiFillBell } from 'react-icons/ai'
import { BsGrid1X2Fill, BsFillFilePersonFill } from "react-icons/bs"
import { TiMessages } from 'react-icons/ti'
import Trade from "./Trade/Trade";
import Details from "./Trade/Details";
import Filesinsidesubcategory from "./Folders/Filesinsidesubcategory";
import Support from "./Support";
import Clients from "./Clients/Clients";
import ClientRegister from "./Clients/ClientRegister";
import ClientFolders from "./Clients/ClientFolders";
import Admins from "./Admins/Admins";
import AdminRegister from "./Admins/AdminRegister";
import AdminFolders from "./Admins/AdminFolders";
import Privileges from "./Privileges/Privileges";
import PrivilegeRegister from "./Privileges/PrivilegeRegister";
function Upload(props) {
  const [sidebar, setsidebar] = useState(true);
  const [curUser, setcurUser] = useState("");
  const [showFolderlst, setshowFolderlst] = useState(false);
  const [category, setCategory] = useState(false)
  const { fetchUserFolder, fetchFolders } = props;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const comp = userInfo.company.split(' ')
  const manual = userInfo.user_type==3?'/Client manual.pdf':userInfo.user_type==2?'/Admin manual.pdf':'';
  function openManual() {
    window.open(manual,'_blank')
  }
  useEffect(() => {
    const load = async () => {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      await setcurUser(user);
      if (user) {
        await fetchUserFolder(user.user_id, user.user_type,user.created_by);
      }
      if (props.userFolder.id !== undefined) {
        await fetchFolders(props.userFolder.id)
      }
    };
    load();
  }, [fetchUserFolder, fetchFolders, props.userFolder.id]);
  
  const signOutHandler = () => {
    swal("Are your sure to SignOut?", {
      buttons: {
        cancel: "Let me back",
        sure: {
          text: "I'm, Sure",
          value: "sure",
        },
      },
    }).then((value) => {
      switch (value) {
        case "sure":
          swal("Successfully logged out!", "", "success").then((val) => {
            props.removeuserFolders();
            props.removeuserFiles();
            props.removeuserrootFolders();
            localStorage.removeItem("token");
            localStorage.removeItem("userInfo");
            instance.get(`/deleteUserFolder/${userInfo.name}-${userInfo.id}`).then(res=>{

            }).catch(err=>console.log(err))
            return props.history.push("/signin");
          });
          break;
        default:
          swal("Got away safely!", "", "success");
      }
    });
  };

  const CategoryHandler = (category_name) => {
    if (category.cat_name === category_name && category.open_cat === true) {
      setCategory({
        ...category,
        cat_name: category_name,
        open_cat: false
      })
      return;
    }
    setCategory({
      ...category, cat_name: category_name, open_cat: true
    })
  }

  const showSidebar = () => setsidebar(!sidebar);
  const show = userInfo.user_type == 1 || userInfo.user_type == 4?'d-none':'';
  return (
    <div
      
      className="w-100 d-flex"
    >
      <aside
        style={{ height: "100vh", backgroundColor: "white", overflow: "auto" }}
        className={
          sidebar
            ? "nav-menu active position-relative overflow-auto sidebar"
            : "nav-menu position-relative overflow-auto sidebar"
        }
      >
        <img className="img-fluid"
          style={{ height: "80px" }}
          src="/LOGO.png"
          alt="" />
        <NavLink
          style={{ textDecoration: "none" }}
          exact
          to="/profile/folders/profilepic"
          className="d-flex flex-column align-items-center"
        >
          <div
            style={{ width: "6rem", height: "6rem", backgroundColor: "white", borderRadius: "50%" }}
            className="d-flex justify-content-center align-items-center rounded-circle mt-0"
          >
            {curUser.profile_pic ? <img src={`../../../uploads/${curUser.profile_pic}`} className="rounded-circle" alt="profile img" style={{ width: "6rem", height: "6rem", objectFit: "cover", borderRadius: "160px" }} /> : <i style={{ color: "grey" }} className="p-5 ri-user-fill fs-1"></i>}
          </div>
  
        </NavLink>
        {/* <NavLink
          style={{ textDecoration: "none" }}
          exact
          to="/profile/folders/userinfo"
          className="d-flex flex-column align-items-center"
        > */}
          <p className="text-white mb-0">{curUser.name}</p>
          <p className="text-white">{comp?comp[0]+'-'+userInfo.user_id:userInfo.company+'-'+userInfo.user_id}</p>
        {/* </NavLink> */}
        <div className="mt-4 p-1 w-100 mx-auto" style={{ borderTop: "1px solid white" }}></div>
        <button
          onClick={() => {
            if (props.location.pathname === "/profile") {
              return swal("Already there!");
            }
            props.history.push('/profile')
          }}
          className="container d-flex justify-content-start align-items-center mt-2 px-3 py-2 border-0 text-white" style={{ background: "transparent" }}
        >
          <i className="ri-home-4-line"></i>
          <span style={{ marginLeft: "1rem" }}>Home</span>
        </button>

        {
          userInfo.user_type === 1 ? <button
            className="container d-flex justify-content-start align-items-center mt-2 px-3 py-2 border-0 text-white" style={{ background: "transparent" }}
            onClick={() => props.history.push(`/profile/privileges/`)}
          >
            <i className="ri-user-2-fill"></i>
            <span style={{ marginLeft: "1rem" }}>Previliged user</span>
          </button>:''
        }
        {
          userInfo.user_type === 4 || userInfo.user_type === 1 ? <button
            className="container d-flex justify-content-start align-items-start mt-2 px-3 py-2 border-0 text-white" style={{ background: "transparent" }}
            onClick={() => props.history.push(`/profile/admins/`)}
          >
            <i className="ri-user-2-fill"></i>
            <span style={{ marginLeft: "1rem" }}>Professional Intermediary</span>
          </button> : ''
        }
        {
          userInfo.user_type === 2 || userInfo.user_type === 4 || userInfo.user_type === 1? <button
            className="container d-flex justify-content-start align-items-center mt-2 px-3 py-2 border-0 text-white" style={{ background: "transparent" }}
            onClick={() => props.history.push(`/profile/clients/`)}
          >
            <i className="ri-user-2-fill"></i>
            <span style={{ marginLeft: "1rem" }}>Direct Clients</span>
          </button>
           : ''
        }
        {
          userInfo.user_type===2 || userInfo.user_type===3 ?
          (
            <>
              <button
                className={"container d-flex justify-content-start align-items-center mt-2 px-3 py-2 border-0 text-white dropdown-toggle " + show} style={{ background: "transparent" }}
                onClick={() => setCategory(!category)}
              >

                <i className="ri-file-list-line"></i>
                <span style={{ marginLeft: "1rem" }}>Reports</span>
              </button>
              {category ?
                <div style={{ background: 'white', borderRadius: '60px 0 0 60px', padding: '0.5rem', paddingRight: '0', marginRight: '0', marginLeft: '1rem', width: '100%' }}>
                  <Trade />
                </div>
                : ''}

              <button
                onClick={() => setshowFolderlst(!showFolderlst)}
                className={"container d-flex justify-content-start align-items-center mt-2 px-3 py-2 border-0 text-white dropdown-toggle " + show} style={{ background: "transparent" }}
              >
                <i className="ri-folder-2-fill"></i>
                <span style={{ marginLeft: "1rem" }}>My folders</span>
              </button>
              {showFolderlst ? <Singlefile /> : ""}
              <button
                className={"container d-flex justify-content-start align-items-center mt-2 px-3 py-2 border-0 text-white " + show} style={{ background: "transparent" }}
                onClick={() => props.history.push(`/profile/folders`)}
              >
                <i className="ri-file-add-fill"></i>
                <span style={{ marginLeft: "1rem" }}>Upload</span>
              </button>
              </>
          ):''
        }

        <button
          className={"container d-flex justify-content-start align-items-center mt-2 px-3 py-2 border-0 text-white "+show} style={{ background: "transparent" }}
          onClick={() => props.history.push(`/profile/support`)}
        >
          <i className="ri-customer-service-line"></i>
          <span style={{ marginLeft: "1rem" }}>Support</span>
        </button>
        
          <button
            className={"container d-flex justify-content-start align-items-center mt-2 px-3 py-2 border-0 text-white "+show} style={{ background: "transparent" }}
            onClick={openManual}
          >
            <i className="ri-file-2-fill"></i>
            <span style={{ marginLeft: "1rem" }}>Manual
        </span>
          </button>
        
      </aside>
      <section
        style={{ height: "100vh", backgroundColor: "white", overflow: "auto" }}
        className={sidebar ? "right-section active-right" : "right-section"}
      >
        <div
          style={{ height: "3rem", backgroundColor: "#c1dcff" }}
          className="w-100 d-flex justify-content-between align-items-center px-5"
        >
          <i
            onClick={showSidebar}
            className={
              sidebar
                ? "ri-close-fill text-primary fs-2"
                : "ri-menu-line text-primary fs-2"
            }
          ></i>
          
 
          <div className="d-flex justify-content-between align-items-center">

            <TiMessages className="text-primary" style={{ height: "2rem", margin: '1rem', width: "2rem" }} />

            <AiFillBell className="text-warning" style={{ height: "1.5rem", margin: '1rem', width: "1.5rem" }} />

            <BsGrid1X2Fill className="text-primary" style={{ height: "1.5rem", margin: '1rem', width: "1.5rem" }} />
            <NavLink
              style={{ textDecoration: "none" }}
              exact
              to="/profile/folders/userinfo"
              className="d-flex flex-column align-items-center"
            >
            <BsFillFilePersonFill className="text-primary" style={{ height: "1.5rem", margin: '1rem', width: "1.5rem" }} />
            </NavLink>
            <AiOutlineLogout className="text-danger" style={{ height: "1.5rem", margin: '1rem', width: "1.5rem" }} onClick={signOutHandler}
              data-toggle="tooltip" data-placement="left" title="Logout" />
            
          </div>
        </div>
        <Switch>

          <Route
            exact
            path="/profile/folders/upload/:id/:name"
            component={Fileupload}
          />
          <Route
            exact
            path="/profile/folders/upload/:id/:name/:user_id"
            component={Fileupload}
          />
          <Route
            exact
            path="/profile/folders/userinfo"
            component={Userprofile}
          />
          <Route
            exact
            path="/profile/folders/profilepic"
            component={Userprofilepic}
          />
          <Route exact path="/profile/folders/:id" component={Filesinfolders} />
          <Route exact path="/profile/folders/files/:id/:name/:user_id" component={Filesinfolders} />
          <Route exact path="/profile/folders/:id/:category/:sub_category" component={Filesinsidesubcategory} />
          <Route exact path="/profile/folders/:id/:category" component={Details} />
          <Route exact path="/profile/folders" component={Myfiles} />
          <Route exact path="/profile" component={AccountData} />
          <Route exact path="/profile/support" component={Support} />
          <Route exact path="/profile/clients" component={Clients} />
          <Route exact path="/profile/clients/:userid/:usertype" component={Clients} />
          <Route exact path="/profile/register/clients" component={ClientRegister} />
          <Route exact path="/profile/clients/folders/:id/:name" component={ClientFolders} />
          <Route exact path="/profile/admins" component={Admins} />
          <Route exact path="/profile/register/admins" component={AdminRegister} />
          <Route exact path="/profile/register/admins/:userid/:usertype" component={AdminRegister} />
          <Route exact path="/profile/admins/folders/:id/:name" component={AdminFolders} />
          <Route exact path="/profile/privileges" component={Privileges} />
          <Route exact path="/profile/register/privileges" component={PrivilegeRegister} />
          <Route exact path="/profile/privileges/admins/:userid/:usertype" component={Admins} />
        </Switch>
      </section>
    </div>
  );
}
const mapDispatchToProps = {
  removeuserFolders,
  removeuserFiles,
  fetchUserFolder,
  removeuserrootFolders,
  fetchFolders
};
const mapStateToProps = (state) => {
  return {
    userFolder: state.getUserFolder.userfolder
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Upload));
